// Variable overrides
$themecolor: #fff;
$themecolor-alt: #26c6da;
$sidebar: #fff;
$sidebar-alt:#f9f9f9;
$sidebar-text: #333;
$sidebar-icons: #fff; 
$shadow:1px 0px 20px rgba(0, 0, 0, 0.08);
$lft:left;
$rgt:right;
$sidemenu-font-color: #fff;
$topbar-color:#fff;
$sidebar-color:#333;
$status-link-hover-color: #00C292;
$status-link-danger-hover-color: #FF0000;
$settings-card-hover: #00C292;